/**
 * @description 工具
*/

/**
 * @description 正则校验（规则包含大写字母、小写字母、数字和!-_#?@特殊字符至少3种
 * @description 不包含邮箱账号
*/
export const pwdCheck = (val) => {
    let reg = /(([!@#\-_]+(([0-9]+([a-z]+|[A-Z]+))|([a-z]+([0-9]+|[A-Z]+))|([A-Z]+([0-9]+|[A-Z]+))))|([0-9]+(([!@#\-_]+([a-z]+|[A-Z]+))|([a-z]+([!@#\-_]+|[A-Z]+))|([A-Z]+([!@#\-_]+|[A-Z]+))))|([a-z]+(([0-9]+([!@#\-_]+|[A-Z]+))|([!@#\-_]+([0-9]+|[A-Z]+))|([A-Z]+([0-9]+|[A-Z]+))))|([A-Z]+(([0-9]+([a-z]+|!@#\-_]+))|([a-z]+([0-9]+|[!@#\-_]+))|([!@#\-_]+([0-9]+|[!@#\-_]+)))))([!@#\-_0-9a-zA-Z]*){8,64}/;
    let req_email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if ((reg.test(val)) && !req_email.test(val)) {
        return true;
    }
    return false;
}