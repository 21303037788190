import { getFormConfig } from "./configs/formConfig.js";
import { resetPwd } from '@/api/password';

export default {
  data: function () {
    const formConfig = getFormConfig(this)

    return {
      formData: {}, //筛选条件
      ...formConfig
    }
  },
  methods: {
    async handleSubmit() {
      let success = await this.$refs.filterForm.validate();
      if (!success) {
        return;
      }

      let { USER_ID, password } = this.$refs.filterForm.formData;

      let {status, message} = await resetPwd({
        USER_ID,
        PASSWORD: password
      });

      if (status === 200) {
        this.$message.success(message)
      }
      
    },
  }
}