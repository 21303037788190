import { getUserLists } from '@/api/auth';
import { pwdCheck } from '../../utils';

export const getFormConfig = (vm) => {

  /**
   * @description 自定义规则
  */
  const defineValidator = (rule, value, callback) => {
    if (pwdCheck(value)) {
      callback();
    } else {
      callback(new Error('注意密码格式'));
    }
  }

  /**
   * @description 判断密码输入是否一致
  */
  const same = (rule, value, callback) => {
    let { password } = vm.$refs.filterForm.formData
    if (value === '') {
      callback(new Error('请再次输入密码'));
    } else if (value !== password) {
      callback(new Error('两次输入密码不一致!'));
    } else {
      callback();
    }
  }

  return {
    filterFormConfig: [
      {
        key: "username",
        label: "用户名",
        type: "search",
        props: {
          valueKey: 'name',
        },
        placeholder: "请选择有效标记",
        fetchHandle: async (payload, cb) => {
          const {data,status,message} = await getUserLists({
            USER_ID: vm.$refs.filterForm.formData?.username,
            PAGE_NUM: 1,
            PAGE_SIZE: 50
          })

          let res = data.list?.map(item => ({
            ...item,
          }))

          cb(res);
        },
        on: {
          select: params => {
            vm.$refs.filterForm.formData.USER_ID = params.userId
          },
        },
        required: true
      },
      {
        key: "password",
        label: "密码",
        type: 'input',
        placeholder: "请输入密码",
        required: true,
        rules: [
          { min: 8, max: 64, message: '长度在 8 到 64 个字符', trigger: 'blur' },
          { validator: defineValidator, trigger: 'blur' }
       ]
      },
      {
        key: "rePassword",
        label: "重复密码",
        type: 'input',
        placeholder: "请再次输入密码",
        required: true,
        rules: [
          { min: 8, max: 64, message: '长度在 8 到 64 个字符', trigger: 'blur' },
          { validator: same, trigger: 'blur' },
       ]
      },
    ]
  }
}